<template>
  <section class="Programas text-center" data-aos="fade-down">
    <div class="pb-5 pt-4 container-wetforest">
      <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> Español </span>
        </h2>
      </div>

      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-5"
        type="text"
        v-model="dataES.title"
      />

      <div
        class="row m-0 mb-3 container-programa align-items-center"
        v-for="card in dataES.cards"
        :key="card.id"
      >
        <div class="w-100 p-3 contain-title d-none d-md-block">
          <input
            class="form-control form-control-lg wet-form card-title mb-0 text-start fw-bold"
            type="text"
            v-model="card.title"
          />
        </div>
        <div class="col-12 col-md-4 py-5">
          <img
            id="programaAssessmentImagen"
            :src="require(`../../assets/${card.img}`)"
            :alt="`Imagen de programa ${card.title}`"
            loading="lazy"
            class="program-image"
          />
        </div>
        <div class="col-12 col-md-8 pt-3">
          <div class="contain-info py-4">
            <div class="w-100 pe-3 text-start">
              <div class="container" v-if="card.content">
                <ckeditor
                  :editor="editor"
                  v-model="card.content"
                  :config="editorConfig"
                ></ckeditor>
              </div>

              <ul v-if="card.list">
                <li class="mb-2" v-for="item in card.list" :key="item.id">
                  <textarea
                    class="form-control wet-form list-item mb-2 tex-start"
                    rows="2"
                    type="text"
                    v-model="item.text"
                  />
                </li>
              </ul>
              <div class="row m-0 my-5">
                <div class="col-12 col-md-6 mb-4 mb-md-0" v-if="card.seeMore">
                  <input
                    class="form-control wet-form body2 mb-2"
                    type="text"
                    v-model="card.seeMoreText"
                    style="width: 84%"
                  />

                  <button class="btn btn-success green text-white d-flex">
                    <img
                      src="@/assets/img/icono_descargar_programa.svg"
                      width="22"
                      height="22"
                      alt="Ícono descargar programas"
                      loading="lazy"
                    />
                    <input
                      class="form-control wet-form body2 mx-2"
                      type="text"
                      v-model="card.seeMoreButtonText"
                    />
                  </button>
                </div>

                <div class="col-12 col-md-6 mb-4 mb-md-0" v-if="card.action">
                  <input
                    class="form-control wet-form body2 mb-2"
                    type="text"
                    v-model="card.actionText"
                    style="width: 84%"
                  />
                  <button class="btn btn-success text-white green d-flex">
                    <img
                      src="@/assets/img/icono_analiza_tu_empresa.svg"
                      width="25"
                      height="25"
                      alt="Ícono analiza tu empresa"
                      loading="lazy"
                    />
                    <input
                      class="form-control wet-form body2 mx-2"
                      type="text"
                      v-model="card.actionButtonText"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-start ms-8">
        <button
          class="btn btn-success btn-lg mt-3 mb-3"
          @click="syncContent('ES')"
        >
          Guardar
        </button>
      </div>
    </div>

    <div class="w-100">
      <hr class="pill my-5" />
    </div>

    <div class="pb-5 pt-4 container-wetforest">
      <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> English </span>
        </h2>
      </div>

      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-5"
        type="text"
        v-model="dataEN.title"
      />

      <div
        class="row m-0 mb-3 container-programa align-items-center"
        v-for="card in dataEN.cards"
        :key="card.id"
      >
        <div class="w-100 p-3 contain-title d-none d-md-block">
          <input
            class="form-control form-control-lg wet-form card-title mb-0 text-start fw-bold"
            type="text"
            v-model="card.title"
          />
        </div>
        <div class="col-12 col-md-4 py-5">
          <img
            id="programaAssessmentImagen"
            :src="require(`../../assets/${card.img}`)"
            :alt="`Imagen de programa ${card.title}`"
            loading="lazy"
            class="program-image"
          />
        </div>
        <div class="col-12 col-md-8 pt-3">
          <div class="contain-info py-4">
            <div class="w-100 pe-3 text-start">
              <div class="container" v-if="card.content">
                <ckeditor
                  :editor="editor"
                  v-model="card.content"
                  :config="editorConfig"
                ></ckeditor>
              </div>

              <ul v-if="card.list">
                <li class="mb-2" v-for="item in card.list" :key="item.id">
                  <textarea
                    class="form-control wet-form list-item mb-2 tex-start"
                    rows="2"
                    type="text"
                    v-model="item.text"
                  />
                </li>
              </ul>
              <div class="row m-0 my-5">
                <div class="col-12 col-md-6 mb-4 mb-md-0" v-if="card.seeMore">
                  <input
                    class="form-control wet-form body2 mb-2"
                    type="text"
                    v-model="card.seeMoreText"
                    style="width: 84%"
                  />

                  <button class="btn btn-success green text-white d-flex">
                    <img
                      src="@/assets/img/icono_descargar_programa.svg"
                      width="22"
                      height="22"
                      alt="Ícono descargar programas"
                      loading="lazy"
                    />
                    <input
                      class="form-control wet-form body2 mx-2"
                      type="text"
                      v-model="card.seeMoreButtonText"
                    />
                  </button>
                </div>

                <div class="col-12 col-md-6 mb-4 mb-md-0" v-if="card.action">
                  <input
                    class="form-control wet-form body2 mb-2"
                    type="text"
                    v-model="card.actionText"
                    style="width: 84%"
                  />
                  <button class="btn btn-success text-white green d-flex">
                    <img
                      src="@/assets/img/icono_analiza_tu_empresa.svg"
                      width="25"
                      height="25"
                      alt="Ícono analiza tu empresa"
                      loading="lazy"
                    />
                    <input
                      class="form-control wet-form body2 mx-2"
                      type="text"
                      v-model="card.actionButtonText"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-start ms-8">
        <button
          class="btn btn-success btn-lg mt-3 mb-3"
          @click="syncContent('EN')"
        >
          Save
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { getSection, putSection } from "../../helpers/contenido";
axios.defaults.withCredentials = true;

export default {
  name: "ProgramasAdmin",
  data() {
    return {
      dataES: {},
      dataEN: {},
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
  methods: {
    async fetchContent() {
      const section = await getSection("programs");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "programs",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "programs",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchContent();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}
.titleBanner {
  font-family: "InterMedium";
  font-size: 1.3vw;
  color: #414141;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  display: block;
  margin-top: 5%;
}

.program-image {
  max-width: 250px;
  width: 100%;
}

.titleBannerlow {
  font-family: "GentiumBold";
  font-size: 3.4vw;
  color: #414141;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
  display: block;
}

.Programas {
  position: relative;
}

.sub-titulo {
  color: #414141;
  font-family: "InterMedium";
  font-size: 1.2vw;
  text-align: left;
}

.green {
  background-color: #23af4d !important;
}

.green:hover {
  background-color: #209c45 !important;
}

.container-programa {
  border: 1px solid #cae6d3;
  border-radius: 8px;
  background-color: #fff;
  /* padding: 1rem; */
  height: 100%;
  position: relative;
}

.container-programa .contain-title {
  box-shadow: 0px 3px 6px #14993c21;
  border-bottom: 1px solid #cae6d3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/*.container-programa .contain-img {*/
/*  width: 25%;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  padding-top: 1rem;*/
/*}*/

/*.container-programa .contain-info {*/
/*  width: calc(100% - 400px);*/
/*}*/

.container-programa .contain-img img.img-2 {
  width: 350px;
  height: 280px;
}

.container-wetforest {
  padding-left: 8%;
  padding-right: 8%;
}

.container-programa .contain-title {
  box-shadow: 0px 3px 6px #14993c21;
  border-bottom: 1px solid #cae6d3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.btn-success-programas {
  font-family: InterMedium;
  background-color: #23af4d !important;
  border-color: #23af4d !important;
  padding: 0.375rem 1.2rem;
  border-radius: 0.5rem;
  width: 64px;
}

@media (min-width: 982px) {
  .btn-success-programas:hover {
    width: 150px;
    transition: all ease 0.4s;
  }
}

h4 {
  text-align: left;
  font-size: 1.2rem;
}

ul {
  list-style: none; /* Remove default bullets */
}

.list-item::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #14993c; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

@media (max-width: 560px) {
  h4 {
    font-size: 1.5rem;
  }
}
</style>
